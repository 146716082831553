import React from 'react';
import quoteImage from '../assets/images/icons/quote.svg';
import ReadMore from './ReadMore';
import TestimonialAvatar from './TestimonialAvatar';

const TestimonialsSliderItem = ({ testimonial }) => {

  const { quote, author, authorPosition, image } = testimonial;

  return (
    <div className='testimonials__quote'>
      <img src={ quoteImage } alt='quote' />
      <ReadMore text={ quote } />
      {/* <p className='testimonials__quote__text'></p> */ }
      <div className='d-flex mt-auto'>
        <TestimonialAvatar image={image} />
        <div>
          <h4 className='testimonials__quote__author'>{ author }</h4>
          <p className='testimonials__quote__author-position'>{ authorPosition }</p>
        </div>
      </div>
    </div>
  )
}

export default TestimonialsSliderItem;
