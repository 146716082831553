import React, { useState } from 'react';

import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import checkmark from '../assets/images/icons/checkmark.svg';

const ContactForm = () => {
  const [formData, setFormData] = useState({});
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [successfullySent, setSuccessfullySentSent] = useState(false);
  const [showInputFile, setShowInputFile] = useState(false);
  const [inputFilesError, setInputFilesError] = useState({ status: false, message: '' });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({ mode: 'onBlur' });

  const submitFormAndShowCaptcha = data => {
    setFormData(data);
    if (!inputFilesError.status) {
      setShowCaptcha(true);
    }
  };

  const resetFormMessage = () => {
    setSuccessfullySentSent(true);

    setTimeout(() => {
      document.querySelector('#contactform').reset();
      setSuccessfullySentSent(false);
    }, 3000);
  };

  const handleShowInputFiles = e => {
    if (e.target.value === 'job') {
      setShowInputFile(true);
    } else {
      setShowInputFile(false);
      setInputFilesError({ status: false, message: '' });
    }
  };

  const sendEmail = () => {
    const data = new FormData();
    data.append('full_name', formData.name || '');
    data.append('email', formData.email || '');
    data.append('company_name', formData.company || '');
    data.append('type', formData.option || '');
    data.append('message', formData.message || '');
    data.append('privacy', formData.privacy || false);
    for (let i = 0; i < formData?.files?.length; i++) {
      data.append('files[]', formData.files[i]);
    }

    axios
      .post('https://softelm-api.stage.softelm.com/api/project-mail', data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(response => {
        if (response.status === 200) {
          setShowCaptcha(false);
          resetFormMessage();
        }
      })
      .catch(error => {
        alert('We have some issues with submiting your data, please try again later');
        document.querySelector('#contactform').reset();
        setShowCaptcha(false);
      });
  };

  const onInputFiles = e => {
    let files = Array.from(e.target.files);

    if (files.length > 3) {
      setInputFilesError({ status: true, message: 'There are more files than 3.' });
      setShowCaptcha(false);
      return;
    } else {
      const isFileTooLarge = files.some(file => file.size > 3072000);
      if (isFileTooLarge) {
        setInputFilesError({ status: true, message: 'Files are larger than 3.0Mb.' });
        setShowCaptcha(false);
        return;
      }
      setInputFilesError({ status: false, message: '' });
    }
  };

  return (
    <form onSubmit={handleSubmit(submitFormAndShowCaptcha)} id='contactform' encType='multipart/form-data'>
      <div className='mt-3'>
        <label htmlFor='name' className='form-label small'>
          Full Name
        </label>
        <div className='position-relative'>
          <input
            type='text'
            className={`form-control offcanvas-contact__input ${errors.name ? 'error' : ''}`}
            id='name'
            aria-describedby='name'
            placeholder='My name is'
            {...register('name', { required: true, minLength: 2, maxLength: 40 })}
          />
          <span className={`position-absolute ${errors.name ? 'error__icon' : ''}`}></span>
        </div>
      </div>
      <div className='mt-3'>
        <label htmlFor='email' className='form-label small'>
          Email
        </label>
        <div className='position-relative'>
          <input
            type='email'
            className={`form-control offcanvas-contact__input ${errors.email ? 'error' : ''}`}
            id='email'
            aria-describedby='email'
            placeholder='My email is'
            {...register('email', { required: true, pattern: /^\S+@\S+$/i })}
          />
          <span className={`position-absolute ${errors.email ? 'error__icon' : ''}`}></span>
        </div>
      </div>
      <div className='mt-3'>
        <label htmlFor='company' className='form-label small'>
          Company name
        </label>
        <input
          type='text'
          className='form-control offcanvas-contact__input'
          id='company'
          aria-describedby='company'
          placeholder='My company name is'
          {...register('company', { required: false, maxLength: 20 })}
        />
        <div className='position-relative'>
          <select
            className={`form-select offcanvas-contact__input mt-3 ${errors.option ? 'error' : ''}`}
            aria-label='Default select example'
            {...register('option', { required: true })}
            onChange={handleShowInputFiles}
          >
            <option value='' hidden>
              I'm interested in...
            </option>
            <option value='web-design'>Web Design</option>
            <option value='application-development'>Application Development</option>
            <option value='job'>Job/Internsheep</option>
          </select>
          <span className={`position-absolute ${errors.option ? 'error__icon' : ''}`}></span>
        </div>
      </div>
      {showInputFile && (
        <div className='mt-3 position-relative'>
          <input
            onInput={onInputFiles}
            className={`form-control offcanvas-contact__input offcanvas-contact__input-file ${
              errors.files ? 'error' : ''
            }`}
            type='file'
            name='files'
            id='formFileMultiple'
            accept='.doc, .docx, .txt, .pdf'
            multiple
            {...register('files', { required: true, value: '' })}
          />
          <span className={`position-absolute ${errors.files ? 'error__icon' : ''}`}></span>
        </div>
      )}
      {inputFilesError.status && (
        <p className='mt-2' style={{ color: 'red' }}>
          {inputFilesError.message}
        </p>
      )}
      <div className='mt-3'>
        <label htmlFor='message' className='form-label small'>
          Message
        </label>
        <div className='position-relative'>
          <textarea
            className={`form-control offcanvas-contact__input ${errors.message ? 'error' : 'green'}`}
            id='message'
            rows='3'
            placeholder='Message'
            {...register('message', { required: true })}
          />
          <span className={`position-absolute ${errors.message ? 'error__icon' : ''}`}></span>
        </div>
      </div>
      <div className='form-check custom-checkbox mt-3 d-flex align-items-center'>
        <input
          className='form-check-input '
          type='checkbox'
          value=''
          id='flexCheckDefault'
          {...register('privacy', { required: 'You need to accept the privacy and policy.' })}
        />
        <label className='form-check-label ms-2' htmlFor='flexCheckDefault'>
          I understand the{' '}
          <Link to='/' className='privacy-policy-link'>
            Privacy Policy
          </Link>{' '}
          and agree with its terms.
        </label>
      </div>
      {errors.privacy && (
        <p className='mt-2' style={{ color: 'red' }}>
          {errors.privacy.message}
        </p>
      )}
      {showCaptcha && (
        <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} onChange={sendEmail} className='mt-3' theme='dark' />
      )}
      {successfullySent && (
        <div className='confirmation-message mt-3 d-flex align-items-center'>
          <img src={checkmark} alt='checkmark' />
          <p className='ms-2'>Message successfully sent!</p>
        </div>
      )}
      <button type='submit' className='offcanvas-contact__button button button--primary mt-3'>
        Submit
      </button>
    </form>
  );
};

export default ContactForm;
