import React from 'react'
import NavbarMenu from './NavbarMenu'

import logo from '../assets/images/logo.svg';
import Contact from './Contact';
import { Link } from 'react-router-dom';

function Navbar() {
  return (
    <nav className='navbar'>
      <div className='container'>
        <div className='navbar__logo col'>
          <Link to='/'>
            <img src={ logo } alt='Softelm Logo' />
          </Link>
        </div>
        <div className='navbar__buttons d-flex aligin-items-center justify-content-end'>
          <button className='button button--primary' type='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvas-contact" aria-controls="offcanvas-contact">Start a project</button>
          <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarMenu" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
      </div>
      <NavbarMenu />
      <Contact />
    </nav>
  )
}

export default Navbar
