import React from 'react';
// import RingLoader from "react-spinners/RingLoader";
import MoonLoader from "react-spinners/MoonLoader";
// import ClipLoader from "react-spinners/ClipLoader";



function Spinner() {
  // let [loading, setLoading] = useState(true);
  return (
    <div className='spinner-holder container h-100 d-flex justify-content-center align-items-center' style={{minHeight: '50vh'}}>
      {/* <RingLoader color='#FFD500' loading={loading} /> */}
      <MoonLoader color='#FFD500' size={120}/>
      {/* <ClipLoader color='#FFD500' loading={loading} size={150} /> */}
      {/* Loading */}
    </div>
  )
}

export default Spinner;
