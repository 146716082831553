import React, { useEffect, useState } from 'react';

import Slider from 'react-slick';

import getMembers from '../api/services/members';
import TeamSliderItem from './TeamSliderItem';
import SliderArrow from './SliderArrow';

function TeamSlider() {
  const [team, setTeam] = useState([]);

  const getAllMembers = async () => {
    const { data } = await getMembers();
    if (!data) return;
    setTeam(data);
  };

  useEffect(() => {
    getAllMembers();
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: team.length >= 4 ? 4 : team.length,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SliderArrow customClass='next-arrow' />,
    prevArrow: <SliderArrow customClass='prev-arrow' />,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className='team__slider'>
      <Slider {...settings}>
        {team.length > 0 &&
          team.map((member, index) => <TeamSliderItem member={member} key={index} />)}
      </Slider>
    </div>
  );
}

export default TeamSlider;
