import axios from '../axios';

const getMembers = async () => {
  try {
    const { data } = await axios.get('/api/members');
    return data;
  } catch (error) {
    console.error('Error while fetching team members: ', error);
  }
};

export default getMembers;
