import React from 'react';
import email from '../assets/images/icons/email.svg';
import phone from '../assets/images/icons/phone.svg';
import linkedin from '../assets/images/icons/linkedin.svg';
import facebook from '../assets/images/icons/facebook.svg';
import instagram from '../assets/images/icons/instagram.svg';
import logo from '../assets/images/logo.svg';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className='container'>
      <hr className='footer__line col-12' />
      <div className='row'>
        <p className='caption col-12'>Let’s make something great together</p>
        <h2 className='footer__coa col-12'>Have an idea? We can help you to</h2>
        <div className='footer__coa--arrow col-12 col-md-6 d-flex align-items-center'>
          <h2 className='' data-bs-toggle="offcanvas" data-bs-target="#offcanvas-contact" aria-controls="offcanvas-contact">Start your project</h2>
          <svg width="24" height="22" viewBox="0 0 24 22" fill="#fff" xmlns="http://www.w3.org/2000/svg">
            <path d="M-5.24032e-07 9.38966L-3.92898e-07 12.3356L18.3146 12.3356L11.3126 19.1453L13.4097 21.1621L24 10.8626L13.4097 0.540489L11.3126 2.60265L18.3146 9.38966L-5.24032e-07 9.38966Z" />
          </svg>
        </div>
        {/* <h2 className='footer__coa--arrow col-12 col-md-6' data-bs-toggle="offcanvas" data-bs-target="#offcanvas-contact" aria-controls="offcanvas-contact">Start your project</h2> */ }
      </div>
      <div className='row footer__info'>
        <div className='col-12 col-lg-8 col-xl-6'>
          <div className='row align-items-center'>
            <div className='footer__info__logo col-12 col-lg-5'>
              <Link to='/'>
                <img src={ logo } alt='logo' />
              </Link>
              <p className='d-none d-lg-block footer__info--lower'>&copy; All rights reserved – Softelm</p>
            </div>
            <div className='col-6 col-lg-3 footer__info__company'>
              <p><b>PIB: </b>112038735</p>
              <p className='footer__info--lower'><b>MB: </b>21594938</p>
            </div>
            <div className='col-6 col-lg-4'>
              <p><a href='mailto:office@softelm.com'><img src={ email } alt='email' />office@softelm.com</a></p>
              <p className='footer__info--lower'><a href='tel:+381607638356'><img src={ phone } alt='phone' />+381 60 76 38 356</a></p>
            </div>
          </div>
        </div>
        <div className='footer__soc-med col-12 col-lg-4 col-xl-6'>
          <div className='footer__soc-med__icons d-flex justify-content-center justify-content-lg-end'>
            <a href='https://www.linkedin.com/company/softelm/' target='_blank' rel="noreferrer"><img src={ linkedin } alt='linkedin' /></a>
            <a href='https://www.facebook.com/softelm/' target='_blank' rel="noreferrer"><img src={ facebook } alt='facebook' /></a>
            <a href='https://www.instagram.com/softelm.doo/' target='_blank' rel="noreferrer"><img src={ instagram } alt='instagram' /></a>
          </div>
          <div className='footer__info--lower d-flex justify-content-center justify-content-lg-end'>
            <Link to='/' className='me-2'>Privacy Policy</Link>
            <Link to='/' className='ms-2'>Terms & Conditions</Link>
          </div>
          <p className='d-flex justify-content-center d-lg-none footer__info--lower'>&copy; All rights reserved – Softelm</p>
        </div>
      </div>
    </div>
  )
}

export default Footer;
