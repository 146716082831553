import React from 'react'
import Navbar from './Navbar'

function PageNotFound() {
  return (
    <div className='page-not-found'>
      <Navbar />
      <div className='container'>
        <div className='row'>
          <div className='page-not-found__message offset-md-4 col-md-4 d-flex flex-column justify-content-center align-items-center'>
            <h1>404<span>!</span></h1>
            <h4>Oops! The page you are looking for does not exist. It might have been moved or deleted.</h4>
            <button className='button button--primary' type='button' data-bs-toggle="offcanvas" data-bs-target="#offcanvas-contact" aria-controls="offcanvas-contact">Start a project</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PageNotFound
