import React from 'react';
import aboutimg from '../assets/images/aboutus_background.png';
import aboutimgMobile from '../assets/images/aboutus_mobile_background.png';
import bootstrap from '../assets/images/icons/technologies/bootstrap.svg';
import css from '../assets/images/icons/technologies/css.svg';
import jquery from '../assets/images/icons/technologies/jquery.svg';
import angular from '../assets/images/icons/technologies/angular.svg';
import reacticon from '../assets/images/icons/technologies/react.svg';

function AboutUs() {
  return (
    <>
      <div className='container'>
        <p className='caption'>WHAT WE CAN DO FOR YOU?</p>
        <div className='row align-items-center'>
          <div className='about-us__text col-12 col-lg-4'>

            <h2>We transform your necessity into reality!</h2>
            <p>Softelm team is group of people that share passion for developing cutting edge web technologies for their clients.</p>
          </div>
          <div className='about-us__tabs col-12 col-md-4 col-lg-3'>
            <div className='nav flex-column nav-pills' id='technologies-tabs' role='tablist' aria-orientation='vertical'>
              <button className='about-us__tabs__tab nav-link active' id='frontend-technologies-tab' data-bs-toggle='pill' data-bs-target='#frontend-technologies-box' type='button' role='tab' aria-controls='frontend-technologies-box' aria-selected='true'><h4>Frontend development</h4></button>
              <button className='about-us__tabs__tab nav-link' id='backend-technologies-tab' data-bs-toggle='pill' data-bs-target='#backend-technologies-box' type='button' role='tab' aria-controls='backend-technologies-box' aria-selected='false'><h4>Backend development</h4></button>
              <button className='about-us__tabs__tab nav-link' id='server-technologies-tab' data-bs-toggle='pill' data-bs-target='#server-technologies-box' type='button' role='tab' aria-controls='server-technologies-box' aria-selected='false'><h4>Server side</h4></button>
            </div>
          </div>
          <div className='about-us__tabs-content tab-content col-12 col-md-8 col-lg-5'>
            <div className='tab-pane fade show active' id='frontend-technologies-box' role='tabpanel' aria-labelledby="frontend-technologies-tab">
              <h3>Frontend development</h3>
              <p>In our projects we usually use Bootstrap themes for CSS, with custom edits if needed. We also prefer jQuery to create dynamic frontend content.</p>
              <div className='d-flex justify-content-between'>
                <div className='technologies-item'>
                  <img src={ bootstrap } alt='bootstrap' className='technologies-icon' />
                  <small>Bootstrap</small>
                </div>
                <div className='technologies-item'>
                  <img src={ css } alt='CSS' className='technologies-icon' />
                  <small>CSS</small>
                </div>
                <div className='technologies-item'>
                  <img src={ jquery } alt='jQuery' className='technologies-icon' />
                  <small>jQuery</small>
                </div>
                <div className='technologies-item'>
                  <img src={ angular } alt='Angular' className='technologies-icon' />
                  <small>Angular</small>
                </div>
                <div className='technologies-item'>
                  <img src={ reacticon } alt='React' className='technologies-icon' />
                  <small>React</small>
                </div>
              </div>
            </div>
            <div className='tab-pane fade' id='backend-technologies-box' role='tabpanel' aria-labelledby="backend-technologies-tab">
              <h3>Backend development</h3>
              <p>For backend coding, we are working with PHP only, and we really enjoy using most popular MVC framework Laravel. We worked with Laravel 4.1, 4.2, and now we are using 5.1+ versions.</p>
            </div>
            <div className='tab-pane fade' id='server-technologies-box' role='tabpanel' aria-labelledby="server-technologies-tab">
              <h3>Server side</h3>
              <p>Even tho our projects are cross platform, we usually prefer to deploy them on CentOS or Ubuntu web servers with Webuzo/Cpanel. We also have our guy for server administration!</p>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 about-us__coa d-none d-md-block'>
            <img src={ aboutimg } className='about-us__coa__img' alt='' />
            <div className='about-us__coa__text' data-bs-toggle="offcanvas" data-bs-target="#offcanvas-contact" aria-controls="offcanvas-contact">
              <h2>Have an idea? We can help you to</h2>
              <div className='about-us__coa__text--arrow d-flex align-items-center'>
                <h2 className=''>Start your project</h2>
                <svg width="24" height="22" viewBox="0 0 24 22" fill="#fff" xmlns="http://www.w3.org/2000/svg">
                  <path d="M-5.24032e-07 9.38966L-3.92898e-07 12.3356L18.3146 12.3356L11.3126 19.1453L13.4097 21.1621L24 10.8626L13.4097 0.540489L11.3126 2.60265L18.3146 9.38966L-5.24032e-07 9.38966Z" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='col-12 about-us__coa d-md-none'>
        <img src={ aboutimgMobile } className='about-us__coa__img' alt='' />
        <div className='container'>
          <div className='about-us__coa__text' data-bs-toggle="offcanvas" data-bs-target="#offcanvas-contact" aria-controls="offcanvas-contact">
            <h2>Have an idea? We can help you to</h2>
            <div className='about-us__coa__text--arrow d-flex align-items-center'>
              <h2 className=''>Start your project</h2>
              <svg width="24" height="22" viewBox="0 0 24 22" fill="#0D0D0D" xmlns="http://www.w3.org/2000/svg">
                <path d="M-5.24032e-07 9.38966L-3.92898e-07 12.3356L18.3146 12.3356L11.3126 19.1453L13.4097 21.1621L24 10.8626L13.4097 0.540489L11.3126 2.60265L18.3146 9.38966L-5.24032e-07 9.38966Z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutUs
