import React from 'react';
import { Link } from 'react-router-dom';

const NavbarMenuLinks = () => {

  let navigateToSection = (section) => {
    if (document.getElementById('navbarMenu')) {
      let offcanvas = document.getElementById('navbarMenu');
      offcanvas.addEventListener('hidden.bs.offcanvas', () => {
        // document.getElementById(section).scrollIntoView();
        setTimeout(() => {
          document.getElementById(section).scrollIntoView();
        }, 500)
      })
    }
  }

  return (
    <div className='col-12 col-md-5 offcanvas__body__nav order-1 order-md-2'>
      <ul className="navbar-nav me-auto mb-2 mb-lg-0">
        <li className="nav-item h1">
          <Link to='/'>
            <span className="nav-link active" aria-current="page" data-bs-dismiss="offcanvas" aria-label="Close" onClick={ () => navigateToSection('about-us') }>Services</span>
          </Link>
        </li>
        <li className="nav-item h1">
          <Link to='/#portfolio'>
            <span className="nav-link" data-bs-dismiss="offcanvas" aria-label="Close">Our work</span>
          </Link>
        </li>
        <li className="nav-item h1">
          <Link to='/#testimonials'>
            <span className="nav-link" data-bs-dismiss="offcanvas" aria-label="Close">Testimonials</span>
          </Link>
        </li>
        <li className="nav-item h1">
          <Link to='/#team'>
            <span className="nav-link" data-bs-dismiss="offcanvas" aria-label="Close">Our Experts</span>
          </Link>
        </li>
        <li className="nav-item h1">
          <span
            className="nav-link"
            data-bs-dismiss="offcanvas"
            aria-label="Close"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvas-contact"
            aria-controls="offcanvas-contact"
          >
              Get in Touch
          </span>
        </li>
      </ul>
    </div>
  )
}

export default NavbarMenuLinks;
