import React from 'react';
import arrow from '../assets/images/icons/right_arrow.svg';
import ContactForm from './ContactForm';

const Contact = () => {
  return (
    <div className="offcanvas offcanvas-end offcanvas-contact" tabIndex="-1" id="offcanvas-contact" aria-labelledby="offcanvas-contact-label">
      {/* <div className="offcanvas-header">
      </div> */}
      <div className="offcanvas-body offcanvas-contact__body">
        <button type="button" className="text-reset offcanvas-contact__close-button" data-bs-dismiss="offcanvas" aria-label="Close">
          <img src={ arrow } alt='Close' />
        </button>
        <h3 className='mt-3'>We love new projects!<br/>If you're ready to get going fill out the form bellow and let's get rolling!</h3>
        <ContactForm />
      </div>
    </div>
  )
}

export default Contact;
