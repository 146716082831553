import React from 'react'

function TestimonialAvatar({image}) {
  let authorImage;

  if(image === '' || undefined){
    authorImage = require(`../assets/images/testimonials/avatar.png`).default;
  } else {
    authorImage = require(`../assets/images/testimonials/${image}`).default;
  }

  return (
    <div className='testimonials__quote__author-image'>
      <img src={ authorImage } alt='person img' />
    </div>
  )
}

export default TestimonialAvatar;
