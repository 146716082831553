import React, { useState, useEffect } from 'react'
import Slider from "react-slick";
import TestimonialsSliderItem from './TestimonialsSliderItem';
import SliderArrow from './SliderArrow';


function TestimonialsSlider() {

  const [testimonials, setTestimonials] = useState([]);
  
  useEffect(()=>{
    fetch('../../data.json')
      .then(response => response.json())
      .then(data => setTestimonials(data.testimonials))
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    nextArrow: <SliderArrow customClass='next-arrow' />,
    prevArrow: <SliderArrow customClass='prev-arrow' />,
    responsive: [
      {
        breakpoint: 1366,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1
        }
      }
    ],
  };

  return (
    <div id='testimonials-slider'>
      <Slider { ...settings }>
        { testimonials.length > 0 && testimonials.map((t, i) => <TestimonialsSliderItem testimonial={t} key={i}/>) }
      </Slider>
    </div>
  )
}

export default TestimonialsSlider;
