import React from 'react';
import TeamSlider from './TeamSlider';

function Team() {
  return (
    <div className='container'>
      <p className='caption col-12'>Team members</p>
      <div className='row team__about-section'>
        <h2 className='col-12 col-lg-5'>None of us is as smart<br />as all of us.</h2>
        <p className='col-12 offset-md-1 col-md-6'>Great things in business are never done by one person;<br/>they're done by a team of people.</p>
      </div>
      <div className='row'>
        <div className='col-12'>
          <TeamSlider />
        </div>
      </div>
    </div>
  )
}

export default Team;
