import React from 'react';


const TeamSliderItem = ({ member }) => {
  const { name, last_name, position, image_path } = member;

  return (
    <div className='team__slider__box'>
      <img src={ image_path } alt='img' className='team__slider__box__image w-100' />
      <div className='team__slider__box__text'>
        <p className='team__slider__box__text--position'>{position}</p>
        <h4 className='team__slider__box__text--name'>{`${name} ${last_name}`}</h4>
      </div>
    </div>
  )
}

export default TeamSliderItem;
