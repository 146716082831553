import './App.scss';
import PageNotFound from './components/PageNotFound';


import { BrowserRouter, Routes, Route } from 'react-router-dom';
import HomePage from './components/HomePage';
import ProjectPage from './components/ProjectPage';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={ <HomePage /> } />
        <Route path='/projects/:id' element={ <ProjectPage /> } />
        <Route path='*' element={ <PageNotFound /> } />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
