import React from 'react';
import PortfolioItemLinkBox from './PortfolioItemLinkBox';
import placeholder from '../assets/images/projects/project-item-placeholder.png';
// import arrow from '../assets/images/icons/right_arrow.svg';
const PortfolioItem = ({ project }) => {
  const { project_folder } = project;
  let image = placeholder;

  if(project_folder !== ''){
    image = require(`../assets/images/projects/${project_folder}/thumbnail.png`).default;
  }

  return (
    <div className='col-12 col-md-6 col-lg-4' style={{marginTop: '30px'}}>
      <div className='portfolio__tabs__content__box'>
        <img className='w-100' src={image} alt='project_cover_image' />
        <PortfolioItemLinkBox project={project} />
      </div>
    </div>
  )
}

export default PortfolioItem;
