import React, { useState } from 'react';

const ReadMore = ({text}) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {setIsReadMore(!isReadMore)};

  return (
    <p className='testimonials__quote__text'>
      {isReadMore ? text.slice(0, 200): text }
      {text.length > 200 && 
        <span onClick={toggleReadMore} style={{color: '#FFD500', cursor: 'pointer'}}>
          {isReadMore ? '...read more' : ' ...show less'}
        </span>
      }
    </p>
  )
}

export default ReadMore;
