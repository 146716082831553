import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

import Navbar from './Navbar';
import Hero from './Hero';
import AboutUs from './AboutUs';
import Portfolio from './Portfolio';
import Testimonials from './Testimonials';
import Footer from './Footer';
import Team from './Team';
import Spinner from './Spinner';

function HomePage() {
  const [spinner, setSpinner] = useState(true);
  const {hash} = useLocation();

  useEffect(() => {
    setSpinner(false)
  }, []);
  
  useEffect(() => {
    if (hash) {
      let elem = document.getElementById(hash.slice(1))
      if (elem && !spinner) {
        setTimeout(()=>{
          elem.scrollIntoView();
        }, 500)
      }
    } else {
      window.scrollTo(0,0)
    }
  }, [hash, spinner])

  return (
    <>
      {
        spinner ? <Spinner /> :
          <>
            <header className='header'>
              <Navbar />
              <Hero />
            </header>
            <main>
              <section className='about-us' id='about-us'>
                <AboutUs />
              </section>
              <section className='portfolio' id='portfolio'>
                <Portfolio />
              </section>
              <section className='testimonials' id='testimonials'>
                <Testimonials />
              </section>
              <section className='team' id='team'>
                <Team />
              </section>
            </main>
            <footer className='footer'>
              <Footer />
            </footer>
          </>
      }
    </>

  )
}

export default HomePage;
